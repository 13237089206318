import { useLocalStorageCondition } from '@bendcircular/conditional-components-react';
import * as React from 'react';
import { ENDPOINTS } from 'src/config';
import { fetcher } from 'src/utils/httpService';
import { hasPermission } from 'src/utils/permissions';
import useSWR, { mutate } from 'swr';
import { Berechtigung, useCurrentUser } from '../User/hooks';

export enum EmissionsFaktorTyp {
  Standard = 1,
  Individual = 2,
}

export enum ErfassungsTyp {
  Zaehlerstand = 1,
  Menge = 2,
}

export enum EnergieSparte {
  Strom = 1,
  Wasser = 2,
  Waerme = 3,
  Gas = 4,
  StromEinspeisung = 5,
  StromProduktion = 6,
  BHKW = 7,
}

export enum HeizungsTyp {
  Erdgas = 11,
  Strom = 17,
  Waermepumpe = 16,
}

export enum ZaehlpunktRelationsTyp {
  Keine = 0,
  Parent = 1,
  Child = 2,
}

const FALLBACK = '#ff00d0';

export const useConfig = () => {
  const { data, error } = useSWR(ENDPOINTS.API_CONFIG, fetcher, {
    revalidateOnFocus: false,
  });

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.DEV_API_MEINE_KOMMUNE_DATA_GET);
  }, []);

  const getColorByCategory = React.useCallback(
    (category) => data?.gebaeudeKategorieFarben?.[category] || FALLBACK,
    [data]
  );

  return {
    refetch,
    getColorByCategory,
    config: data || {},
    isLoading: !error && !data,
    error,
  };
};

interface UseFeatureFlag {
  feature: string;
}
export const useFeatureFlag = ({ feature }: UseFeatureFlag) => {
  const { data, error } = useSWR(ENDPOINTS.API_FEATURE_CONFIG, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  const isActivated = useLocalStorageCondition({
    condition: feature || '',
    additionals: data,
  });

  return {
    featureConfig: data || {},
    isLoading: !error && !data,
    isActivated,
    error,
  };
};

interface UseGlobalFeatureConfig {
  feature: string;
}
export const useGlobalFeatureConfig = ({ feature }: UseGlobalFeatureConfig) => {
  const { data, error } = useSWR(ENDPOINTS.API_GLOBAL_FEATURE_CONFIG, fetcher, {
    revalidateOnFocus: false,
  });

  const isActivated = useLocalStorageCondition({
    condition: feature || '',
    additionals: data,
  });

  return {
    featureConfig: data || {},
    isLoading: !error && !data,
    isActivated,
    error,
  };
};

export const useHasPermission = ({
  permission,
  striktCheck,
}: {
  permission: Berechtigung;
  striktCheck?: boolean;
}) => {
  const { user } = useCurrentUser();

  return {
    hasPermission: hasPermission(permission, user?.berechtigungen, striktCheck),
  };
};
