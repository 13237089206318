import { getIn, useFormikContext } from 'formik';
import * as React from 'react';
import LimboFormikDatePicker from 'src/components/atoms/form-fields/limboDatePicker';
import LimboFormikNumberField from 'src/components/atoms/form-fields/limboNumberField';
import {
  ERROR_DECREASING_NUMBER,
  ERROR_INCREASING_NUMBER,
} from 'src/modules/Zaehlpunkt/constants';
import { validateDecimalPlacesOfNumber } from 'src/utils/formatNumbers';
import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import { validate } from 'src/utils/yup';
import * as Yup from 'yup';
import {
  decreasingNumber,
  increasingOrEqualNumber,
  isUnique,
} from '../../validation';
import { getZaehlerStaendeByNewDate } from '../helper';

export const ZAEHLERSTAND_ZEITPUNKT = {
  fieldName: 'zeitpunkt',
  label: 'Datum',
  validationSchema: (zaehlerstaende: any) =>
    validate(
      // @ts-ignore
      Yup.date()
        .required('Bitte Datum auswählen')
        .typeError('Ungültiges Datumsformat')
        .max(new Date(), 'Darf nicht in der Zukunft liegen')
        .test({
          message: 'Datum muss einmalig sein',
          test: isUnique(zaehlerstaende, ZAEHLERSTAND_ZEITPUNKT.fieldName),
        })
    ),
};

export const ZaehlerstandZeitpunkt = (props: any) => {
  const { zaehlerstaende, ...datePickerProps } = props;
  const { values, setFieldValue } = useFormikContext();
  const currentZeitpunkt = getIn(values, ZAEHLERSTAND_ZEITPUNKT.fieldName);
  const { small } = useSmallDevice();

  React.useEffect(() => {
    if (small && !currentZeitpunkt) {
      setFieldValue(ZAEHLERSTAND_ZEITPUNKT.fieldName, new Date(), true);
    }
  }, [small, setFieldValue, currentZeitpunkt]);

  return (
    <LimboFormikDatePicker
      name={ZAEHLERSTAND_ZEITPUNKT.fieldName}
      label={ZAEHLERSTAND_ZEITPUNKT.label}
      validate={ZAEHLERSTAND_ZEITPUNKT.validationSchema(zaehlerstaende)}
      dateFormat="yyyy-MM-dd"
      {...datePickerProps}
    />
  );
};

export const ZAEHLERSTAND_MESSWERT = {
  fieldName: 'wert',
  label: (unitOfMeasuring) => `Zählerstand in ${unitOfMeasuring}`,
  validationSchema: (zaehlerstaende: any, newRecord: any) =>
    validate(
      Yup.lazy((value: any) => {
        return value === ''
          ? Yup.string().required('Bitte Messwert eingeben.')
          : Yup.number()
              .required('Bitte Messwert eingeben.')
              .typeError('Bitte Messwert eingeben.')
              .min(0, 'Wert darf nicht negativ sein')
              .test({
                message: 'Bitte keine Nachkommastellen eingeben',
                test: validateDecimalPlacesOfNumber(0),
              })
              .test({
                message: ERROR_INCREASING_NUMBER,
                test: increasingOrEqualNumber(
                  zaehlerstaende,
                  newRecord,
                  'wert',
                  'nulldurchgang'
                ),
              })
              .test({
                message: ERROR_DECREASING_NUMBER,
                test: decreasingNumber(
                  zaehlerstaende,
                  newRecord,
                  'wert',
                  'nulldurchgang'
                ),
              });
      })
    ),
};
export const NULLDURCHGANG = {
  fieldName: 'nulldurchgang',
};

export const ZaehlerstandMesswert = ({
  zaehlerstaende,
  zaehlpunkt,
  unitOfMeasuring,
  ...textfieldProps
}: any) => {
  const listOfZaehlerwechsel = React.useMemo(
    () =>
      zaehlerstaende.length > 0 &&
      zaehlerstaende.map((zaehlerstand: any) => zaehlerstand.zaehlerwechsel),
    [zaehlerstaende]
  );
  const { values, setFieldValue } = useFormikContext();
  const wert = getIn(values, ZAEHLERSTAND_MESSWERT.fieldName);
  const zeitpunkt = getIn(values, ZAEHLERSTAND_ZEITPUNKT.fieldName);
  const nulldurchgang = getIn(values, NULLDURCHGANG.fieldName);

  const filteredZaehlerstaende = React.useMemo(
    () =>
      getZaehlerStaendeByNewDate({
        zaehlerstaende,
        newDate: zeitpunkt,
        zaehlernummern: zaehlpunkt?.zaehlernummern,
      }),
    [zaehlerstaende, zaehlpunkt?.zaehlernummern, zeitpunkt]
  );

  const newRecord = { zeitpunkt, nulldurchgang };

  React.useEffect(() => {
    setFieldValue(ZAEHLERSTAND_MESSWERT.fieldName, wert, true);
  }, [nulldurchgang, listOfZaehlerwechsel, wert, setFieldValue]);

  return (
    <LimboFormikNumberField
      name={ZAEHLERSTAND_MESSWERT.fieldName}
      label={ZAEHLERSTAND_MESSWERT.label(unitOfMeasuring)}
      validate={ZAEHLERSTAND_MESSWERT.validationSchema(
        filteredZaehlerstaende,
        newRecord
      )}
      decimalScale={0}
      {...textfieldProps}
    />
  );
};

export const ZAEHLERSTAND_MESSWERT_STROM = {
  fieldName: 'wertStrom',
  label: (unitOfMeasuring) => `Strom Zählerstand in ${unitOfMeasuring}`,
  validationSchema: (zaehlerstaende: any, newRecord: any) =>
    validate(
      Yup.lazy((value: any) => {
        return value === ''
          ? Yup.string().required('Bitte Messwert eingeben.')
          : Yup.number()
              .required('Bitte Messwert eingeben.')
              .typeError('Bitte Messwert eingeben.')
              .min(0, 'Wert darf nicht negativ sein')
              .test({
                message: 'Bitte keine Nachkommastellen eingeben',
                test: validateDecimalPlacesOfNumber(0),
              })
              .test({
                message: ERROR_INCREASING_NUMBER,
                test: increasingOrEqualNumber(
                  zaehlerstaende,
                  newRecord,
                  'wertStrom',
                  'nulldurchgangStrom'
                ),
              })
              .test({
                message: ERROR_DECREASING_NUMBER,
                test: decreasingNumber(
                  zaehlerstaende,
                  newRecord,
                  'wertStrom',
                  'nulldurchgangStrom'
                ),
              });
      })
    ),
};

export const NULLDURCHGANG_STROM = {
  fieldName: 'nulldurchgangStrom',
};

export const ZaehlerstandMesswertStrom = ({
  zaehlerstaende,
  zaehlpunkt,
  unitOfMeasuring,
  ...textfieldProps
}: any) => {
  const listOfZaehlerwechsel = React.useMemo(
    () =>
      zaehlerstaende.length > 0 &&
      zaehlerstaende.map((zaehlerstand: any) => zaehlerstand.zaehlerwechsel),
    [zaehlerstaende]
  );
  const { values, setFieldValue } = useFormikContext();
  const wert = getIn(values, ZAEHLERSTAND_MESSWERT_STROM.fieldName);
  const zeitpunkt = getIn(values, ZAEHLERSTAND_ZEITPUNKT.fieldName);
  const nulldurchgangStrom = getIn(values, NULLDURCHGANG_STROM.fieldName);

  const filteredZaehlerstaende = React.useMemo(
    () =>
      getZaehlerStaendeByNewDate({
        zaehlerstaende,
        newDate: zeitpunkt,
        zaehlernummern: zaehlpunkt?.zaehlernummernStrom,
      }),
    [zaehlerstaende, zaehlpunkt?.zaehlernummernStrom, zeitpunkt]
  );

  const newRecord = { zeitpunkt, nulldurchgangStrom };

  React.useEffect(() => {
    setFieldValue(ZAEHLERSTAND_MESSWERT_STROM.fieldName, wert, true);
  }, [nulldurchgangStrom, listOfZaehlerwechsel, wert, setFieldValue]);

  return (
    <LimboFormikNumberField
      name={ZAEHLERSTAND_MESSWERT_STROM.fieldName}
      label={ZAEHLERSTAND_MESSWERT_STROM.label(unitOfMeasuring)}
      validate={ZAEHLERSTAND_MESSWERT_STROM.validationSchema(
        filteredZaehlerstaende,
        newRecord
      )}
      decimalScale={0}
      {...textfieldProps}
    />
  );
};

export const ZAEHLERSTAND_MESSWERT_WAERME = {
  fieldName: 'wertWaerme',
  label: (unitOfMeasuring) => `Wärme Zählerstand in ${unitOfMeasuring}`,
  validationSchema: (zaehlerstaende: any, newRecord: any) =>
    validate(
      Yup.lazy((value: any) => {
        return value === ''
          ? Yup.string().required('Bitte Messwert eingeben.')
          : Yup.number()
              .required('Bitte Messwert eingeben.')
              .typeError('Bitte Messwert eingeben.')
              .min(0, 'Wert darf nicht negativ sein')
              .test({
                message: 'Bitte keine Nachkommastellen eingeben',
                test: validateDecimalPlacesOfNumber(0),
              })
              .test({
                message: ERROR_INCREASING_NUMBER,
                test: increasingOrEqualNumber(
                  zaehlerstaende,
                  newRecord,
                  'wertWaerme',
                  'nulldurchgangWaerme'
                ),
              })
              .test({
                message: ERROR_DECREASING_NUMBER,
                test: decreasingNumber(
                  zaehlerstaende,
                  newRecord,
                  'wertWaerme',
                  'nulldurchgangWaerme'
                ),
              });
      })
    ),
};

export const NULLDURCHGANG_WAERME = {
  fieldName: 'nulldurchgangWaerme',
};

export const ZaehlerstandMesswertWaerme = ({
  zaehlerstaende,
  zaehlpunkt,
  unitOfMeasuring,
  ...textfieldProps
}: any) => {
  const listOfZaehlerwechsel = React.useMemo(
    () =>
      zaehlerstaende.length > 0 &&
      zaehlerstaende.map((zaehlerstand: any) => zaehlerstand.zaehlerwechsel),
    [zaehlerstaende]
  );
  const { values, setFieldValue } = useFormikContext();
  const wert = getIn(values, ZAEHLERSTAND_MESSWERT_WAERME.fieldName);
  const zeitpunkt = getIn(values, ZAEHLERSTAND_ZEITPUNKT.fieldName);
  const nulldurchgangWaerme = getIn(values, NULLDURCHGANG_WAERME.fieldName);

  const filteredZaehlerstaende = React.useMemo(
    () =>
      getZaehlerStaendeByNewDate({
        zaehlerstaende,
        newDate: zeitpunkt,
        zaehlernummern: zaehlpunkt?.zaehlernummernWaerme,
      }),
    [zaehlerstaende, zaehlpunkt?.zaehlernummernWaerme, zeitpunkt]
  );

  const newRecord = { zeitpunkt, nulldurchgangWaerme };

  React.useEffect(() => {
    setFieldValue(ZAEHLERSTAND_MESSWERT_WAERME.fieldName, wert, true);
  }, [nulldurchgangWaerme, listOfZaehlerwechsel, wert, setFieldValue]);

  return (
    <LimboFormikNumberField
      name={ZAEHLERSTAND_MESSWERT_WAERME.fieldName}
      label={ZAEHLERSTAND_MESSWERT_WAERME.label(unitOfMeasuring)}
      validate={ZAEHLERSTAND_MESSWERT_WAERME.validationSchema(
        filteredZaehlerstaende,
        newRecord
      )}
      decimalScale={0}
      {...textfieldProps}
    />
  );
};
